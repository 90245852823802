export const useCustomToast = () => {
  const toast = useToast();

  function success(description?: string) {
    return toast.add({
      title: "Sucesso!",
      description,
      color: "green",
    });
  }

  function error(description?: string) {
    return toast.add({
      title: "Erro",
      description,
      color: "red",
    });
  }

  return {
    success,
    error,
  };
};
